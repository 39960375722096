@import 'ember-bootstrap/bootstrap';
@import 'ember-power-select';
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import "modals";

.toast {
  max-width: 100%;
  background-color: #2f96b4;
}

.sign-in-prepend {
  width: 54px;
  height: 54px;
}
.min-vh-50 {
  min-height: 50vh !important;
}

.customHeight {
  height: 1200px;
}

.loadingGif {
  background: url(../assets/images/Hourglass.gif);
  padding: 5px 0 5px 25px;
}

// Small devices (576px and up)
@media (min-width: 576px) {
  .btn.btn-export {
    min-width: 170px !important;
    max-width: 170px !important;
    // max-width: 100% !important;
  }
}
