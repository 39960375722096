.notification-dialog {
  position: fixed;
  top: 50px;
  right: 8px;
  z-index: 500;
  min-width: 485px;
  max-width: 485px;
}

.notify-update {
	transform: scale(1);
	animation: pulseGreen 1s 1, jiggle-20 0.3s 2;
}

.notify-update-error {
	transform: scale(1);
	animation: pulseRed 1s 3, jiggle-20 0.3s 2;
}

@keyframes pulseRed {
    0% {
      color: red;
    }

    100% {
      color: inherit;
    }
}

@keyframes pulseGreen {
    0% {
      color: green;
    }

    100% {
      color: inherit;
    }
}

@keyframes jiggle-20 {
	0% {
    transform: rotate(0deg);
	}

  25% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }

	100% {
    transform: rotate(0deg);
	}
}

@keyframes pulse {
	0% {
    transform: scale(1);
    color: inherit;
	}

  33% {
    transform: scale(0.9);
  }

  66% {
    transform: scale(0.8);

  }

	50% {
    color: green;
	}

	100% {
    color: inherit;
    transform: scale(1);
	}
}

$standard-overlay-background-color: #000;

.profile-global-admin-modal-overlay,
.profile-global-admin-modal-wrapper,
.profile-ou-admin-modal-overlay,
.profile-ou-admin-modal-wrapper,
.profile-peer-supporter-modal-overlay,
.profile-peer-supporter-modal-wrapper,
.profile-smrc-counsellor-modal-overlay,
.profile-smrc-counsellor-modal-wrapper,
.article-modal-overlay,
.article-modal-wrapper,
.resource-modal-overlay,
.resource-modal-wrapper,
.canned-message-overlay,
.canned-message-wrapper,
.map-resource-modal-overlay,
.map-resource-modal-wrapper,
.survey-modal-overlay,
.survey-model-wrapper,
.tag-modal-overlay,
.tag-modal-wrapper,
.template-modal-overlay,
.template-modal-wrapper,
.user-modal-overlay,
.user-modal-wrapper,
.push-notification-modal-overlay,
.push-notification-modal-wrapper,
.push-dispatch-modal-overlay,
.push-dispatch-modal-wrapper,
.video-modal-overlay,
.video-modal-wrapper {
  background: rgba($standard-overlay-background-color, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.end-user-activation-tokens-overlay,
.end-user-activation-tokens-wrapper  {
  background: rgba($standard-overlay-background-color, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.end-user-activation-tokens-container {
  width: 95vw;
  min-height: 90vh;
}

.profile-global-admin-modal-container,
.profile-ou-admin-modal-container,
.profile-peer-supporter-modal-container,
.profile-smrc-counsellor-modal-container,
.canned-message-modal-container,
.push-notification-modal-container,
.push-dispatch-modal-container,
.survey-modal-container,
.user-modal-container {
  width: 60vw;
  min-height: 60vh;
}

.tag-modal-container {
  width: 40vw;
  min-height: 30vh;
}

.scrolling-article-container {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  max-height: 60vh;
  padding-right: 1rem !important;
  padding-bottom: 0px !important;
  margin-bottom: 55px;
}


.template-modal-container,
.article-modal-container,
.resource-modal-container,
.video-modal-container {
  background-color: white;
  width: 75vw;
  min-height: 30vh;
  max-height: 80vh;
}

.map-resource-scrolling-container {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  max-height: 70vh;
  padding-right: 1rem !important;
  padding-bottom: 0px !important;
  margin-bottom: 55px;
}

.map-resource-modal-container {
  background-color: white;
  width: 85vw;
  min-height: 30vh;
  max-height: 90vh;
}

// SCROLLBAR OVERIDES
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 140, 200, 0.7) rgba(0, 0, 0, 0);
  scrollbar-width: 10px;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 140, 200, 0.7);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 1);
}


/* field-checkbox */
.checkbox-multiple {
	-webkit-padding-start: 0px;
	padding-top: 0px;

}

.checkbox-multiple > li {
  // width: 25%;
  width: auto;
  text-align: center;
  min-width: 15%;
  display: inline-flex;
  flex-flow: column wrap;
  // display: inline-block;
  list-style-type: none;
  padding-bottom: 5px;
}

.checkbox-multiple > li > label {
  font-weight: normal;
}

.checkbox-multiple > li > label > input {
  margin-right: 5px;
}
